import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, NgZone, inject } from '@angular/core';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular/standalone';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { addIcons } from 'ionicons';
import { alertCircle } from 'ionicons/icons';

export class SvvwErrorHandler implements ErrorHandler {
  private router = inject(Router);
  private toastController = inject(ToastController);
  private translateService = inject(TranslateService);
  private zone: NgZone = inject(NgZone);

  constructor() {
    addIcons({ alertCircle });
  }

  handleError(error: object): void {
    if (!environment.production) {
      console.log('handleError.error', error);
    }

    const key =
      error instanceof HttpErrorResponse && error.status === 0
        ? 'connection'
        : 'general';

    if (
      error instanceof HttpErrorResponse &&
      [400, 401, 403].includes(error.status)
    ) {
      this.zone.run(() => {
        this.router.navigate(['error', error.status]);
      });
    } else {
      const toast = this.translateService.instant(`error.toast.${key}`);
      this.showToast(toast);
    }
  }

  private async showToast(message: string): Promise<void> {
    const toast = await this.toastController.create({
      message,
      color: 'danger',
      icon: 'alert-circle',
      duration: 5000,
      buttons: [
        {
          text: this.translateService.instant('button.close'),
          role: 'cancel',
        },
      ],
    });

    await toast.present();
  }
}
