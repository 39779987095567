import { Component, inject } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { IonApp, IonRouterOutlet, Platform } from '@ionic/angular/standalone';

@Component({
  selector: 'svvw-root',
  templateUrl: 'app.component.html',
  imports: [IonApp, IonRouterOutlet],
})
export class AppComponent {
  private platform = inject(Platform);
  private loadingControl = inject(LoadingController);

  constructor() {
    this.platform.backButton.subscribeWithPriority(
      10,
      async processNextHandler => {
        if (await this.isLoadingActive()) {
          return;
        }

        // If no loading modal is active, allow the default back button behavior
        processNextHandler();
      },
    );
  }

  async isLoadingActive(): Promise<boolean> {
    const topLoading = await this.loadingControl.getTop();

    return !!topLoading;
  }
}
