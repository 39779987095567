import { Location } from '@angular/common';
import {
  HttpClient,
  provideHttpClient,
  withFetch,
  withInterceptors,
} from '@angular/common/http';
import {
  APP_INITIALIZER,
  ErrorHandler,
  ImportProvidersSource,
  enableProdMode,
  importProvidersFrom,
} from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { RouteReuseStrategy, provideRouter } from '@angular/router';
import {
  IonicRouteStrategy,
  provideIonicAngular,
} from '@ionic/angular/standalone';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  AuthenticationService,
  SVNL_SHARED_CONFIG,
  SvnlSharedConfig,
  tokenInterceptor,
} from '@svnl/shared';
import { SvvwAuthenticationService } from 'authentication/authentication.service';
import { SvvwErrorHandler } from 'error/svvw-error-handler';
import { Observable } from 'rxjs';

import { AppComponent } from './app/app.component';
import { routes } from './app/app.routes';
import { AppInitializeService } from './app/init/app-initialize.service';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

const svnlSharedConfig: SvnlSharedConfig = {
  serverUrl: environment.serverUrl,
  authentication: {
    settings: environment.authentication.settings,
    debugLogging: false,
  },
  production: environment.production,
};

const initializeAuthenticationService = (
  authenticationService: SvvwAuthenticationService,
  location: Location,
) => {
  return async (): Promise<void> =>
    await authenticationService.init(location.path());
};

const appInitializer = (appInitializeService: AppInitializeService) => {
  return (): Observable<void> => appInitializeService.initialize();
};

const translateHttpLoaderFactory = (
  httpClient: HttpClient,
): TranslateHttpLoader =>
  new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');

const importProviders: ImportProvidersSource[] = [
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: translateHttpLoaderFactory,
      deps: [HttpClient],
    },
  }),
];
bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(importProviders),
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: SVNL_SHARED_CONFIG, useValue: svnlSharedConfig },
    {
      provide: AuthenticationService,
      useExisting: SvvwAuthenticationService,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AppInitializeService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAuthenticationService,
      multi: true,
      deps: [SvvwAuthenticationService, Location],
    },
    {
      provide: ErrorHandler,
      useClass: SvvwErrorHandler,
    },
    provideIonicAngular(),
    provideRouter(routes),
    provideHttpClient(withFetch(), withInterceptors([tokenInterceptor])),
  ],
});
