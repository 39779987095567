import { inject, Injectable } from '@angular/core';
import {
  InterpolatableTranslationObject,
  TranslateService,
} from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { Constants } from 'shared/constants';

@Injectable({
  providedIn: 'root',
})
export class AppInitializeService {
  private translateService = inject(TranslateService);

  initialize(): Observable<InterpolatableTranslationObject> {
    this.translateService.setDefaultLang(Constants.LANGUAGE_CODE);

    return this.translateService.use(Constants.LANGUAGE_CODE);
  }
}
