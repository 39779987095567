import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    redirectTo: '/start',
    pathMatch: 'full',
  },
  {
    path: 'start',
    loadChildren: () => import('start/start.routes').then(m => m.routes),
  },
  {
    path: 'tabs',
    loadChildren: () => import('tabs/tabs.routes').then(m => m.routes),
  },
  {
    path: 'signin-callback',
    redirectTo: 'tabs/home',
    pathMatch: 'full',
  },
  {
    path: 'signout-callback',
    redirectTo: 'start',
    pathMatch: 'full',
  },
  {
    path: 'error',
    loadChildren: () => import('./error/error.routes').then(m => m.routes),
  },
  {
    path: '**',
    redirectTo: 'error/404',
  },
];
